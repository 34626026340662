import * as React from "react";
import Layout from "../components/Layout/Layout";
import PageHero from "../components/PageHero/PageHero";

const NotFoundPage = () => {
  return (
    <Layout>
      <PageHero title={"404. Sidan du söker efter finns inte."} />
    </Layout>
  );
};

export default NotFoundPage;
